import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlOptionModel, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import CommonService from '@common-src/service/common';
import LabelService from '@common-src/service/label';

export class WatchCameraEntityModel extends BaseEntityModel {
    @FormControl({
        label: '选择镜头',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.cameraList
    } as FormControlOptionModel)
    monitorId: string = undefined;

    // @FormControl({
    //     label: '选择分组',
    //     type: FormControlType.SELECT,
    //     required: true,
    //     optionsPromise: CommonService.cameraGroupKv
    // } as FormControlOptionModel)
    // groupId: string = undefined;
    // groupName: string = undefined;

    @FormControl({
        label: '标签',
        type: FormControlType.SELECT,
        optionsPromise: LabelService.getAllLabel.bind(LabelService),
        mode: 'multiple',
        required: false
    } as FormControlOptionModel)
    tagIds: Array<string> = undefined;
    tags: Array<any> = undefined;

    @FormControl({
        label: '序号',
        type: FormControlType.NUMBER,
        required: false
    } as FormControlTextModel)
    sort: number = undefined;

    name: string = undefined;
    locationName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '序号',
                dataIndex: 'no',
                scopedSlots: { customRender: 'no' }
            },
            {
                title: '排序',
                dataIndex: 'sort'
            },
            {
                title: '展示镜头',
                dataIndex: 'name'
            },
            {
                title: '标签',
                dataIndex: 'tags',
                scopedSlots: { customRender: 'tags' }
            },
            {
                title: '位置',
                dataIndex: 'locationName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json) {
        super.toModel(json);
        this.tagIds = _.map(_.get(json, 'tags'), item => item.id);
        return this;
    }

    toService() {
        // const data: any = super.toService();
        // data.groupId = data.groupName;
        return {
            id: this.id,
            monitorId: this.monitorId,
            tagIds: this.tagIds,
            sort: this.sort
        };
    }
}

export class WatchCameraQueryModel extends QueryPageModel {
    name: string = undefined;
    toService() {
        const data: any = super.toService();
        data.params = {};
        return data;
    }
}
