var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "watch-camera-list-component table-component" },
    [
      _c(
        "div",
        { staticClass: "page-body page-body-margin-left-16" },
        [
          _c(
            "a-card",
            { attrs: { title: "展示镜头" } },
            [
              _c(
                "jtl-button",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.addClick(new _vm.WatchCameraEntityModel())
                    },
                  },
                  slot: "extra",
                },
                [_vm._v("+ 添加")]
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize,
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "no",
                    fn: function (text, record, index) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s((_vm.page - 1) * _vm.pageSize + index + 1)
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "name",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(text))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "tags",
                    fn: function (text, record) {
                      return _vm._l(record.tags, function (item, index) {
                        return _c("a-tag", { key: index }, [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ])
                      })
                    },
                  },
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "确认删除?",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteClick(record)
                              },
                            },
                          },
                          [
                            _c("a", { staticClass: "jtl-del-link" }, [
                              _vm._v("删除"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: {
          dialogOK: function ($event) {
            return _vm.getList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }