





















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { WatchCameraEntityModel, WatchCameraQueryModel } from '@common-src/entity-model/watch-camera-entity';
import WatchCameraService from '@common-src/service/watch-camera';

@Component
export default class WatchCameraListComponent extends TableDialogFormComponent<WatchCameraEntityModel, WatchCameraQueryModel> {
    WatchCameraEntityModel = WatchCameraEntityModel;
    created() {
        this.initTable({
            service: WatchCameraService,
            queryModel: new WatchCameraQueryModel(),
            tableColumns: WatchCameraEntityModel.getTableColumns()
        });
        this.getList();
    }
}

