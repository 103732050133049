
import { WatchCameraEntityModel, WatchCameraQueryModel } from '@common-src/entity-model/watch-camera-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/videoMonitor`;

class WatchCameraService implements ICRUDQ<WatchCameraEntityModel, WatchCameraQueryModel> {
    async create(model: WatchCameraEntityModel):Promise<WatchCameraEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WatchCameraEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new WatchCameraEntityModel().toModel(res);
    }

    async update(model: WatchCameraEntityModel):Promise<WatchCameraEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: WatchCameraEntityModel):Promise<WatchCameraEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WatchCameraQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new WatchCameraEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WatchCameraEntityModel().toModel(item));
    }

    async sort(sourceId: string, targetId: string) {
        const url = `${URL_PATH}/change`;
        const params = { sourceId, targetId };
        const res = await post(url, params);
        return res;
    }
}

export default new WatchCameraService();
